var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class burger_menu_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "toggleOpen", (e) => {
      e.preventDefault();
      this.burgerTarget.classList.toggle(this.activeClass);
      this.menuTarget.classList.toggle(this.activeClass);
    });
  }
  initialize() {
    console.log("[BurgerMenuController::initialize]", true);
  }
  connect() {
    console.log("[BurgerMenuController::connect]", true);
  }
}
__publicField(burger_menu_controller_default, "targets", ["burger", "menu"]);
__publicField(burger_menu_controller_default, "classes", ["active"]);
