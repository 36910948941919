var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
export default class evenfinancial_wall_placeholder_controller_default extends ApplicationController {
  initialize() {
    console.log("[evenfinancial_wall_placeholder::initialize]");
    this.isPerformable = true;
    window.localStorage.setItem("renderEvenFinancialWall", false);
    window.addEventListener("storage", () => {
      const performRender = JSON.parse(window.localStorage.getItem("renderEvenFinancialWall")) && this.isPerformable;
      if (performRender) {
        this.perform();
        this.isPerformable = false;
      }
    });
  }
  connect() {
    console.log("[evenfinancial_wall_placeholder::connect]");
  }
  perform() {
    return __async(this, null, function* () {
      const componentType = this.displayTypeValue == "evenfinancial_card" ? "Cards" : "Wall";
      try {
        const evenfinancialWallInitScript = document.createElement("script");
        evenfinancialWallInitScript.type = "text/javascript";
        evenfinancialWallInitScript.text = `
        if (!document.querySelector('[data-gjs-type]')) {
          const url = new URL(window.location.origin +'/uikit/class_component')
          url.search = new URLSearchParams({ 
            name: "Evenfinancial::${componentType}::Component",
            redirect_url: "${this.redirectUrlValue}",
            external_id: "${this.externalIdValue}"
          })
          const response = fetch(url)
                .then(response => response.text())
                .then((markup) => {
                  const evenfinancial = document.querySelector('[data-controller=evenfinancial-wall-placeholder]')
                  evenfinancial.innerHTML = markup
                })
           
          
        }
      `;
        if (this.placeholderTarget) {
          this.placeholderTarget.innerHTML = "";
          this.placeholderTarget.insertAdjacentElement(
            "afterend",
            evenfinancialWallInitScript
          );
        }
      } catch (e) {
        console.log("e", e);
      }
    });
  }
}
__publicField(evenfinancial_wall_placeholder_controller_default, "targets", ["placeholder"]);
__publicField(evenfinancial_wall_placeholder_controller_default, "values", {
  redirectUrl: String,
  externalId: String,
  displayType: String
});
