var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
const trackerModuleInst = new EventTrackerModule(ahoy);
export default class freestar_ad_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "trackerModuleInst", trackerModuleInst);
  }
  initialize() {
    console.log("[freestarad::initialize] this.pixel", this.trackerModuleInst);
  }
  connect() {
    return __async(this, null, function* () {
      console.log("[freestarad::connect] this.pixel", this.trackerModuleInst);
      yield this.perform();
    });
  }
  perform() {
    return __async(this, null, function* () {
      try {
        const unit = this.unitTarget;
        console.log("[freestarad::perform] unit", unit);
        const slotName = unit.getAttribute("data-ad-id");
        console.log("slot", slotName);
        unit.setAttribute("id", slotName);
        console.log("[freestarad::perform] unit", unit);
        const freestarInitScript = document.createElement("script");
        freestarInitScript.type = "text/javascript";
        freestarInitScript.text = `
        console.log("[freestarad::perform] inside of script tag")
        if (!document.querySelector('[data-gjs-type]')) {
          if (typeof freestar === typeof undefined) {
            var adUnit = document.querySelector("#${slotName}")
            console.log('adUnitinside of script tag', adUnit)
            if (adUnit) adUnit.remove()
          } else {
            if(freestar && freestar.queue) {
              console.log("[freestarad::perform] inside of script tag on place and ready to use", freestar)
              freestar.queue.push(function() {
                freestar.config.enabled_slots.push({
                  placementName: "${slotName}",
                  slotId: "${slotName}"
                });
              }) 
              freestar.queue.push(function() {
                freestar.newStickyFooter("ushousinghelper_Sticky_Footer_2");
              });
            }
          }
        }
      `;
        if (this.unitTarget) {
          this.unitTarget.insertAdjacentElement("afterend", freestarInitScript);
        }
      } catch (e) {
        console.log("e", e);
      }
    });
  }
}
__publicField(freestar_ad_controller_default, "targets", ["unit"]);
