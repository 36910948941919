var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
const trackerModuleInst = new EventTrackerModule(ahoy);
export default class transparently_wall_placeholder_controller_default extends ApplicationController {
  initialize() {
    console.log(
      "[transparently_wall_placeholder::initialize]"
    );
    window.localStorage.setItem("renderTransparentlyWall", false);
    window.addEventListener("storage", () => {
      const performRender = JSON.parse(window.localStorage.getItem("renderTransparentlyWall"));
      if (performRender) {
        window.localStorage.setItem("renderTransparentlyWall", false);
        this.perform();
      }
    });
  }
  perform() {
    return __async(this, null, function* () {
      try {
        const placeholder = this.placeholderTarget;
        console.log("[transparently_wall_placeholder::perform] placeholder", placeholder);
        const transparentlyWallInitScript = document.createElement("script");
        transparentlyWallInitScript.type = "text/javascript";
        transparentlyWallInitScript.text = `
        console.log("[transparently_wall_placeholder::perform] inside of script tag")
        if (!document.querySelector('[data-gjs-type]')) {
          const url = new URL(window.location.origin +'/uikit/class_component')
          url.search = new URLSearchParams({ 
            name: "Transparently::Wall::Component",
            external_id: "${this.campaignIdValue}"
          })
          const response = fetch(url)
                .then(response => response.text())
                .then((markup) => {
                  const transparently = document.querySelector('[data-controller=transparently-wall-placeholder]')
                  transparently.innerHTML = markup
                })
           
          
        }
      `;
        if (this.placeholderTarget) {
          this.placeholderTarget.innerHTML = "";
          this.placeholderTarget.insertAdjacentElement(
            "afterend",
            transparentlyWallInitScript
          );
        }
      } catch (e) {
        console.log("e", e);
      }
    });
  }
}
__publicField(transparently_wall_placeholder_controller_default, "targets", ["placeholder"]);
__publicField(transparently_wall_placeholder_controller_default, "values", {
  campaignId: String
});
__publicField(transparently_wall_placeholder_controller_default, "trackerModuleInst", trackerModuleInst);
