var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
const trackerModuleInst = new EventTrackerModule(ahoy);
export default class trialscope_wall_placeholder_controller_default extends ApplicationController {
  initialize() {
    console.log(
      "[trialscope_wall_placeholder::initialize] this.pixel",
      this.trackerModuleInst
    );
  }
  connect() {
    return __async(this, null, function* () {
      console.log(
        "[trialscope_wall_placeholder::connect] this.pixel",
        this.trackerModuleInst
      );
      yield this.perform();
    });
  }
  perform() {
    return __async(this, null, function* () {
      try {
        console.log("this.redirectUrlValue", this.redirectUrlValue);
        const placeholder = this.placeholderTarget;
        console.log("[trialscope_wall_placeholder::perform] placeholder", placeholder);
        const trialscopeWallInitScript = document.createElement("script");
        trialscopeWallInitScript.type = "text/javascript";
        trialscopeWallInitScript.text = `
        console.log("[trialscope_wall_placeholder::perform] inside of script tag")
        if (!document.querySelector('[data-gjs-type]')) {
          const url = new URL(window.location.origin +'/uikit/class_component')
          url.search = new URLSearchParams({ 
            name: "Trialscope::Wall::Component", 
            redirect_url: "${this.redirectUrlValue}",
            trial_id: "${this.trialIdValue}"
          })
          const response = fetch(url)
                .then(response => response.text())
                .then((markup) => {
                  const trialscope = document.querySelector('[data-controller=trialscope-wall-placeholder]')
                  trialscope.innerHTML = markup
                })
           
          
        }
      `;
        if (this.placeholderTarget) {
          this.placeholderTarget.innerHTML = "";
          this.placeholderTarget.insertAdjacentElement(
            "afterend",
            trialscopeWallInitScript
          );
        }
      } catch (e) {
        console.log("e", e);
      }
    });
  }
}
__publicField(trialscope_wall_placeholder_controller_default, "targets", ["placeholder"]);
__publicField(trialscope_wall_placeholder_controller_default, "values", {
  redirectUrl: String,
  trialId: String
});
__publicField(trialscope_wall_placeholder_controller_default, "trackerModuleInst", trackerModuleInst);
