var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class spinner_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  start() {
    if (this.loadingClass && this.loadingClass.length > 0) {
      this.element.classList.add(...this.loadingClasses);
    }
  }
  stop() {
    if (this.loadingClass && this.loadingClass.length > 0) {
      this.element.classList.remove(...this.loadingClasses);
    }
  }
}
__publicField(spinner_controller_default, "classes", ["loading"]);
