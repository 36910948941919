var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
export default class tooltip_controller_default extends ApplicationController {
  initialize() {
    this.content;
    this.placement = "top";
    if (this.hasPlacementValue)
      this.placement = this.placementValue;
    if (this.hasContentValue) {
      this.content = this.contentValue;
    } else if (this.hasContentObjectValue) {
      let items = "";
      const contentItems = this.contentObjectValue;
      contentItems.map((item) => {
        items += `
          <h2 class="text-[10px] font-bold text-gray-500 leading-tight mt-2">${item.title}</h2>
          <div class="mt-1 text-[10px] text-gray-500 leading-tight">${item.desc}</div>
        `;
      });
      console.log("items:", items);
      this.content = `
        <div class="rounded-lg bg-white p-4 shadow-lg w-96 max-w-[90vw]">
          ${items}
        </div>
      `;
    }
  }
  connect() {
    const toolTip = tippy(this.element, {
      interactive: true,
      placement: this.placement,
      arrow: false,
      theme: "light",
      animation: "fade",
      content: this.content,
      trigger: "click",
      allowHTML: true
    });
  }
}
__publicField(tooltip_controller_default, "values", { content: String, contentObject: Array, placement: String });
