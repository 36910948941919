import Rails from "@rails/ujs";
import ahoy from "ahoy.js";
import * as ActiveStorage from "@rails/activestorage";
import "@hotwired/turbo-rails";
import "./main_p.css";
Rails.start();
ActiveStorage.start();
const currentParams = window.location.search;
const leadid_token = typeof LeadiD != "undefined" && LeadiD.token && typeof LeadiD.token != "undefined" && LeadiD.token;
const additionalParams = {
  "userAgent": navigator.userAgent,
  "urlParams": currentParams,
  "leadid_token": leadid_token
};
const subfolderPrefix = window.location.pathname.split("/")[1];
const isSubfolderValid = subfolderPrefix && subfolderPrefix.length > 0 && subfolderPrefix == "cms";
ahoy.configure({
  urlPrefix: isSubfolderValid ? `/${subfolderPrefix}` : "",
  visitsUrl: "/ahoy/visits",
  eventsUrl: "/ahoy/events"
});
ahoy.trackView(additionalParams);
ahoy.trackClicks("a, button, input[type=submit]", additionalParams);
ahoy.trackSubmits("form", additionalParams);
ahoy.trackChanges("input, textarea, select", additionalParams);
import "controllers";
