var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";
export default class param_condition_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "handleStorageCondition", (condition) => __async(this, null, function* () {
      window.addEventListener("storage", (e) => __async(this, null, function* () {
        if (!this.element)
          return;
        const qStoreModule = yield ResultsStoreModule("questions_results");
        const qStoreObject = (yield qStoreModule.getStore("questions_results")) || {};
        this.matched = qStoreObject[condition.name] === condition.value;
        this.handleMatched();
      }));
    }));
  }
  initialize() {
    this.matched = false;
    this.currentParams = Object.fromEntries(new URLSearchParams(window.location.search));
    this.locationData = JSON.parse(document.body.dataset.sessionLocation || {});
    this.checkConditions();
    console.log("[ParamConditionController::initialize]", this.element);
  }
  connect() {
    console.log("[ParamConditionController::connect]", this.element);
    this.handleMatched();
  }
  checkConditions() {
    return __async(this, null, function* () {
      console.log("[ParamConditionController::checkConditions]", this.currentParams);
      function mapAsync(array, callbackfn) {
        return Promise.all(array.map(callbackfn));
      }
      function filterAsync(array, callbackfn) {
        return mapAsync(array, callbackfn).then((filterMap) => {
          return array.filter((value, index) => filterMap[index]);
        });
      }
      const currentParams = this.currentParams;
      const isMatchedCondition = (condition) => __async(this, null, function* () {
        let matched = false;
        if (condition.checkType === "zip") {
          matched = yield this.checkZip(condition.zipListId, this.locationData.zip);
        } else if (condition.checkType === "storage") {
          matched = this.handleStorageCondition(condition);
        } else {
          matched = currentParams[condition.name] === condition.value;
        }
        return matched;
      });
      this.matched = yield filterAsync(this.conditionsValue, isMatchedCondition);
      this.matched = this.matched.length > 0;
      this.handleMatched();
    });
  }
  checkZip(zipListId, clientZip) {
    return __async(this, null, function* () {
      const url = `/api/v1/zip_lists/${zipListId}/check?zip=${clientZip}`;
      return yield fetch(url).then(function(response) {
        return response.json();
      }).then(function(data) {
        console.log("DATA:::RES", data);
        return data.valid;
      });
    });
  }
  handleMatched() {
    console.log("HANDLE MATCHED", [this.element, this.matched]);
    if (this.matched) {
      const isQuestion = this.element.dataset.controller.includes("chain--question--component");
      this.element.remove();
      if (isQuestion)
        this.chainController.connectCurrentQuestion();
    }
  }
  get chainController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector(".chain"),
      "chain--component"
    );
  }
}
__publicField(param_condition_controller_default, "values", {
  conditions: Array
});
