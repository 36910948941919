var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class modal_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "toggleOpen", (e) => {
      if (e && !this.currentModal) {
        const triggerData = e.currentTarget.dataset;
        this.currentModal = this.currentModalTarget(triggerData.modal);
      }
      if (this.currentModal) {
        this.layoutTarget.classList.toggle(this.hiddenClass);
        this.currentModal.classList.toggle(this.hiddenClass);
        if (this.isActive)
          this.currentModal = null;
        this.isActive = !this.isActive;
      }
    });
    __publicField(this, "setCurrentModal", (trigger) => {
      this.currentModal = this.currentModalTarget(trigger);
    });
  }
  initialize() {
    console.log("[ModalController::initialize]", true);
    this.isActive = false;
    this.currentModal = null;
  }
  connect() {
    console.log("[ModalController::connect]", true);
  }
  currentModalTarget(triggerKey) {
    return this.modalTargets.find((m) => m.dataset.modal === triggerKey);
  }
}
__publicField(modal_controller_default, "targets", ["trigger", "modal", "layout"]);
__publicField(modal_controller_default, "classes", ["hidden"]);
